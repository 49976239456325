@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	background: linear-gradient(300deg, #119b73, #0a4635, #01261c);
	background-size: 120% 120%;
	animation: gradient-animation 10s ease infinite;
	height: 100vh;
}

.App {
	color: rgb(245, 245, 245);
	font-family: 'Poppins', sans-serif;
}

p {
	margin: 0;
}

::-webkit-scrollbar {
	width: 0.4vw;
}

::-webkit-scrollbar-track {
	background-color: #000000;
}

::-webkit-scrollbar-thumb {
	background-color: #ffffff;
}

@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
